:root {
  --mui-palette-primary-main: #3949ab;
  --mui-palette-primary-light: #9fa8da;
  --mui-palette-primary-dark: #1565c0;
  --mui-palette-primary-contrastText: #fff;
  --mui-palette-text-grey: #757575;
  --mui-palette-grey: #e0e0e0;
  /* ...other variables */
}
body {
  background: #000000;
}
.txt-comment fieldset {
  border-radius: 60px;
}
.caption {
  color: #999999;
}
.menu {
  text-decoration: none;
  color: black;
}
.tag {
  display: flex;
  justify-content: flex-end;
}

.card-section {
  display: flex;
  justify-content: center;
}
.card-skeleton {
  display: flex;
  justify-content: center;
  /* margin-top: 6; */
}
.camera-section {
  position: relative;
  width: 100%;
  height: 75vh;
  border-radius: 1rem;
  background: #000000;
  color: #fff;
}
.btn-capture {
  position: absolute;
  bottom: 4rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.result-img {
  position: absolute;
  width: 100%;
  height: 75vh;
  top: 0;
  left: 0;
  border-radius: 1rem;
  background: #000000;
  z-index: 2;
}
.captured-photo {
  position: absolute;
  top: 2rem;
  left: 0;
  z-index: 3;
}
.btn-del {
  position: absolute;
  bottom: 4rem;
  left: 14%;
  z-index: 4;
}
.btn-save {
  position: absolute;
  bottom: 4rem;
  right: 14%;
  z-index: 4;
}
.bg-login {
  width: 100%;
  height: 100%;
  background-image: url("../src/assets/images/bgGrey.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.ribbon {
  --f: 10px; /* control the folded part*/
  --r: 15px; /* control the ribbon shape */
  --t: 47px; /* the top offset */

  position: absolute;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 0 20px var(--f) calc(10px + var(--r));
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
  background: linear-gradient(
    90deg,
    rgb(143, 114, 24) 0%,
    rgba(255, 191, 0, 1) 60%,
    rgba(255, 221, 120, 1) 100%
  );
  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
  z-index: 1;
}
.card-project-right {
  container-type: inline-size;
}
.card-project-right > .day {
  font-size: 30cqi;
  line-height: 35px;
}
.card-project-right > .date {
  font-size: 50cqi;
  line-height: 35px;
}
.card-project-right > .month {
  font-size: 30cqi;
  line-height: 35px;
}
.card-project-right > .year {
  font-size: 30cqi;
  line-height: 25px;
}

#sidebarMenu a {
  padding: 6px 10px 6px 30px;
  display: flex;
  gap: 14px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: transparent;
  color: var(--mui-palette-text-grey);
}
#sidebarSubMenu a {
  padding: 6px 10px 6px 50px;
  display: flex;
  gap: 8px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: transparent;
  color: var(--mui-palette-text-grey);
}
#sidebarMenu a.active,
#sidebarSubMenu a.active {
  background-color: var(--mui-palette-primary-main);
  color: white;
}
#sidebarMenu a:hover:not(.active),
#sidebarSubMenu a:hover:not(.active) {
  background-color: var(--mui-palette-grey);
}
